<template>
<div class="about">
</div>
</template>
<script>
export default {
components:{

},
data(){
 return {};
 },
created(){

},
methods:{

},
};
</script>
<style lang="scss" soped>


</style>