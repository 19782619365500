import axios from "axios"


export const api = axios.create({
    baseURL:'https://hygienechecks.newaptism.cn',
    timeout:''
})


api.interceptors.request.use(req=>{
    return req;
},err=>{
    return Promise.reject(err)
})


api.interceptors.response.use(res=>{
    console.log(res);
    if (res.data.code ==0 || res.data.code == 200 || res.status === 200) {
        return Promise.resolve(res.data)
    }
},err=>{
    return Promise.reject(err)
})

export default api
