<template>
  <div class="home">
    <div
      class="home-max"
      v-loading="loading"
      element-loading-text="正在提交......"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"                               
    >
      <el-form ref="form" :model="form" label-width="80px">
        <h3>卫生视频上传</h3>
        <!-- <el-form-item label="门店名称">
          <el-input v-model="form.name" :disabled="true"></el-input>
        </el-form-item> -->
        <el-form-item label="上传时间">
          <el-col :span="24">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="form.date1"
              style="width: 100%"
              :disabled="true"
            ></el-date-picker>
          </el-col>
        </el-form-item>
           <el-upload
            class="upload-demo"
            :action="UrlPin"
            :before-upload="beforeUpload"
            drag
            :file-list="form.videoList"
            :on-success="handleSuccess"
            :on-preview="handlePreview"
            accept=".mp4,.mov,.jpg,.png"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传MP4/MOV/JPG/PNG文件，且不超过100MB
            </div>
          </el-upload>
      </el-form>
    </div>
    <!-- 预览视频,图片 -->
        <el-dialog  :visible.sync="preVisible" :modal-append-to-body="true">
          <video :src="preUrl" :controls="true" style="width: 100%;height: 400px" v-if="preUrl.substring(preUrl.lastIndexOf('.')) == '.mp4'" ></video>
          <img :src="preUrl" alt="" style="width: 100%;height: auto" v-else >
      </el-dialog>
  </div>
</template>
<script>
import api from '../api/index'
export default {
  components: {
  },
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: new Date(),
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
        // 示例图片\视频
        videoList: [
        ]
      },
      loading: false,
      // 存url问号拼接参
      urlCan:'',
      // 返回视频预览
      // Previewvideo:[],
      // 预览地址
      preUrl: '',
      //拼接参判断哪个模块
      Apitype:'',
      // 门店
      seller:'',
      // 预览弹框
      preVisible: false
    };
  },
  created() {
    // 进入获取?后面的参
    this.urlCan=this.$route.query.DataId
    this.Apitype=this.$route.query.ApiText
    this.seller=this.$route.query.sellername
  },
  methods: {
    // 判断类型和大小
    beforeUpload(file) {
      
    },
    handleSuccess(response, file, fileList) {
      // 处理上传成功后的响应
      console.log('上传成功:', response);
      this.form.videoList.push(response.data)
      // 可以在这里处理后端返回的视频保存信息
      console.log(this.form.videoList);
    },
    handlePreview(file){
      console.log('预览文件:',file)
      this.preUrl = file.response.data
      this.preVisible = true
      console.log(this.preUrl,'<-预览地址');
    },
  },
  // 计算属性
  computed:{
    UrlPin(){
      return (this.Apitype=='卫生视频'?`https://hygienechecks.newaptism.cn/OSS/uploadVideo?dataId=${this.urlCan}`:`https://hygienechecks.newaptism.cn/LinenVideo/uploadLinenVideo?dataId=${this.urlCan}&sellerName=${this.seller}`)
    }
  }
};
</script>
<style lang="scss" soped>
.home {
  background-color: rgb(248, 248, 248);
  height: 100vh;
  overflow: hidden;
}
.home-max {
  width: 92%;
  height: 750px;
  margin: 0 auto;
  margin-top: 5%;
  background-color: #fff;
  overflow: hidden;
}
.el-form {
  padding: 22px;
}
.el-upload--text > div {
  width: 300px;
}
.final{
  margin: 30px 70px 0  0;
}
</style>